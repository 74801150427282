'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

var _request = require('../../common/request');

var _request2 = _interopRequireDefault(_request);

var _commonMethod = require('./commonMethod');

var _commonMethod2 = _interopRequireDefault(_commonMethod);

var _config = require('../../common/config');

var _config2 = _interopRequireDefault(_config);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Login = /** @class */function () {
    function Login() {
        this.wmRequest = new _request2.default();
        this.LoginDom = document.getElementById("loginbox-main");
        this.commonMethod = new _commonMethod2.default(this.LoginDom, true);
        this.search = decodeURIComponent(window.location.search);
        this.init();
    }
    Login.prototype.init = function () {
        this.jump();
        this.loginEvent();
    };
    Login.prototype.jump = function () {
        var that = this;
        var registerBtn = _tool2.default.$_select(this.LoginDom, '.goto-register');
        registerBtn.addEventListener('click', function () {
            window.location.href = "/register".concat(that.search);
        });
        var forgetPasswordBtn = _tool2.default.$_select(this.LoginDom, '.goto-forgetPassword');
        forgetPasswordBtn.addEventListener('click', function () {
            window.location.href = "/forgetPassword".concat(that.search);
        });
    };
    Login.prototype.loginEvent = function () {
        var that = this;
        var emailInputDom = _tool2.default.$_select(this.LoginDom, ".login-email input");
        var passWordInputDom = _tool2.default.$_select(this.LoginDom, ".login-password input");
        var LoginBtn = _tool2.default.$_select(this.LoginDom, ".login-btn");
        var emailInputError = _tool2.default.$_select(this.LoginDom, ".login-email .error-text");
        var passWordInputError = _tool2.default.$_select(this.LoginDom, ".login-password .error-text");
        emailInputDom.addEventListener('input', function () {
            emailInputError.innerHTML = '';
        });
        passWordInputDom.addEventListener('input', function () {
            passWordInputError.innerHTML = '';
        });
        LoginBtn.addEventListener('click', function (e) {
            // 防止正在请求或者点击到提示文案触发点击事件
            var el = e.target;
            if (that.commonMethod.isRequset() || el.nodeName == 'P') {
                return;
            }
            var emailVal = emailInputDom.value;
            var passwordVal = passWordInputDom.value;
            if (!emailVal) {
                emailInputError.innerHTML = _config2.default.Wm_tips_email_empty;
                return;
            }
            if (!_tool2.default.isMail(emailVal)) {
                emailInputError.innerHTML = _config2.default.Wm_tips_email_error;
                return;
            }
            if (!passwordVal) {
                passWordInputError.innerHTML = _config2.default.Wm_tips_password_empty;
                return;
            }
            if (!_tool2.default.passwordReg(passwordVal)) {
                passWordInputError.innerHTML = _config2.default.Wm_tips_password_error;
                return;
            }
            that.commonMethod.showRequstLoading(true);
            that.wmRequest.ajax({
                url: _config2.default.WC_LOGIN,
                type: 'POST',
                data: {
                    email: emailVal,
                    password: passwordVal
                }
            }).then(function (res) {
                that.commonMethod.showRequstLoading(false);
                if (res.code == 200) {
                    that.commonMethod.showRequestStatus(res.msg, 'success');
                    setTimeout(function () {
                        that.commonMethod.showRequestStatus('', 'close');
                        that.willGoto();
                    }, 500);
                } else {
                    that.commonMethod.showRequestStatus(res.msg, 'error');
                }
            }).catch(function (e) {
                that.commonMethod.showRequstLoading(false);
                that.commonMethod.showRequestStatus(e.toString(), 'error');
            });
        });
        document.addEventListener('keydown', function (e) {
            if (e.key == 'Enter') {
                LoginBtn.click();
            }
        });
    };
    Login.prototype.willGoto = function () {
        if (this.search.includes('?goto=/')) {
            var willGotoUrl = this.search.replace('?goto=', '');
            window.location.href = willGotoUrl;
        } else {
            window.location.href = '/';
        }
    };
    return Login;
}();
exports.default = Login;