'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CommonMethod = /** @class */function () {
    function CommonMethod(commonDom, needPassWord) {
        this.commonDom = commonDom;
        this.needPassWord = needPassWord;
        this.init();
    }
    CommonMethod.prototype.init = function () {
        this.needPassWord && this.passWordVisible();
    };
    // 密码显示隐藏
    CommonMethod.prototype.passWordVisible = function () {
        var passwordVisibles = _tool2.default.$_selectAll(this.commonDom, ".password-visible");
        passwordVisibles.forEach(function (passwordVisible) {
            passwordVisible.addEventListener('click', function () {
                if (this.getAttribute('data-visible') == '0') {
                    this.setAttribute('data-visible', '1');
                    this.setAttribute('src', '/static/web/dist/images/login/ic_eye.svg');
                    passwordVisible.parentElement.querySelector("input").setAttribute('type', 'text');
                } else {
                    this.setAttribute('data-visible', '0');
                    this.setAttribute('src', '/static/web/dist/images/login/ic_diseye.svg');
                    passwordVisible.parentElement.querySelector("input").setAttribute('type', 'password');
                }
            });
        });
    };
    // 是否在请求
    CommonMethod.prototype.isRequset = function () {
        var loadingDom = _tool2.default.$_select(this.commonDom, ".request-loading");
        return loadingDom.style.display == 'block';
    };
    // 显示请求加载
    CommonMethod.prototype.showRequstLoading = function (isShow) {
        var btnText = _tool2.default.$_select(this.commonDom, ".login-btn span");
        var loadingDom = _tool2.default.$_select(this.commonDom, ".request-loading");
        if (isShow) {
            btnText.style.display = 'none';
            loadingDom.style.display = 'block';
        } else {
            btnText.style.display = 'block';
            loadingDom.style.display = 'none';
        }
    };
    // 提示请求结果
    CommonMethod.prototype.showRequestStatus = function (msg, status) {
        var requestStatus = _tool2.default.$_select(this.commonDom, ".request-status");
        var statusText = _tool2.default.$_select(this.commonDom, ".request-status p");
        var statusImg = _tool2.default.$_select(this.commonDom, ".status-img");
        requestStatus.style.display = 'flex';
        statusText.innerHTML = msg;
        if (status == 'success') {
            statusImg.setAttribute("src", "/static/web/dist/images/login/request_success.png");
        } else if (status == 'error') {
            statusImg.setAttribute("src", "/static/web/dist/images/login/request_error.png");
        } else if (status == 'close') {
            requestStatus.style.display = 'none';
        }
    };
    return CommonMethod;
}();
exports.default = CommonMethod;